.section3 {
  background-color: var(--orb-dark);
  padding-left: 21vw;
  padding-right: 18vw;
  padding-top: 2.5vw;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 2.5%;
}

.title-session-3 {
  font-family: 'Panton';
  font-weight: 800;
  font-size: 180%;
  font-style: italic;
  color: var(--orb-light-gray);
  margin: 0;
}

.yellow-paralelogram{
  margin-left: 5vw;
  width: 8vw;
  height: 1.2vw;
  transform: skew(-13deg);
  background-color: var(--orb-green);
  border-radius: 1.8px;
}
.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card-innercontainer {
  display: flex;
  width: 28%;
  flex-direction: row;
  border-style: solid;
  border-width: 0.5px;
  border-color: var(--orb-green);
  border-radius: 5px;
  margin-right: 2.5%;
  margin-bottom: 3%;
  height: 9.6vw;
  padding-left: 2.5%;
  align-items: center;
}
.card-image {
  width: 19%;
  margin-right: 8%;
}
.card-description {
  font-family: 'Panton';
  font-weight: 300;
  font-size: 90%;
  color: var(--orb-light-gray);
  margin: 0;
  width: 65%;
}