.header-container {
  display: flex;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0));
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 99999;
  padding-top: 0.6%;
  padding-bottom: 0.6%;
  height: 5vw;
  width: 100%;
  justify-content: center;
}
.header-innercontainer {
  display: flex;
  width: 63%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 75px;
}
.header-left-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
}
.header-left-items>a{
  text-decoration: none;
}
.header-item {
  font-family: 'Panton';
  font-weight: 600;
  color: var(--orb-light-gray);
  font-size: 70%;
  font-style: italic;
  margin-right: 5%;
  width: 70px;
}
.header-item:hover {
  font-family: 'Panton';
  font-weight: 700;
  color: var(--orb-green);
  font-size: 80%;
}
.button-buy {
  font-family: 'Panton';
  font-weight: 700;
  font-size: 65%;
  text-align: center;
  border-radius: 5px;
  width: 150px;
  height: 2.6vw;
	transform: skew(-5deg);
  background-color: var(--orb-green);
  border-style: none;
  margin-left: 20px;
}

.button-buy:hover{
  background-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 2px;
  border-color: var(--orb-green);
  color: #EBF2F2;
}