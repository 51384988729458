body {
  margin: 0px;
  padding: 0px;
}

:root {
  --orb-dark: #092126;
  --orb-dark-blue: #023859;
  --orb-dark-gray: #364C59;
  --orb-green: #C7EE17;
  --orb-light-gray: #EBF2F2;
}

.instruction {
  font-family: "Panton"; 
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

.body-small {
  font-family: "Panton"; 
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.body-base {
  font-family: "Panton"; 
  font-weight: 400;
  font-size: 20px;
  line-height: 24.22px;
}

.h1 {
  font-family: "Panton"; 
  font-weight: 900;
  font-size: 65px;
  line-height: 65px;
}

.h2 {
  font-family: "Panton"; 
  font-weight: 800;
  font-size: 55px;
  line-height: 55px;
}

.h3 {
  font-family: "Panton"; 
  font-weight: 700;
  font-size: 34px;
  line-height: 26px;
}

.h4 {
  font-family: "Panton"; 
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

a.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}
