.section2 {
  display: flex;
  background-color: #F9F9F9;
  height: 38vw;
  padding-left: 21vw;
}
.collumn { 
  display: flex;
  flex-direction: column;
  width: 35%;
  position: relative;
}
.tag-poweking-product {
  display: flex;
  flex-direction: row;
  width: 14vw;
  height: 2vw;
	transform: skew(-13deg);
  background-color: var(--orb-green);
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 6%;
}
.tag-poweking-product-text {
  display: flex;
  flex-direction: row;
  text-align: center;
  font-family: 'Panton';
  font-weight: 500;
  font-size: 65%;
  text-align: center;
  color: var(--orb-dark-blue)
}
.pk {
  margin-left: 0.3vw;
  font-weight: 700;
}
.title {
  font-family: 'Panton';
  font-weight: 800;
  font-size: 180%;
  font-style: italic;
  color: var(--orb-dark-blue);
  width: 100%;
  margin-top: 3%;
}
.description {
  font-family: 'Panton';
  font-weight: 300;
  font-size: 100%;
  color: var(--orb-dark-blue);
  width: 90%;
  margin-top: 0;
}
.subDescription {
  font-family: 'Panton';
  font-weight: 300;
  font-size: 80%;
  color: var(--orb-dark-blue);
  width: 90%;
  margin-top: 0;
}
.decoration{
  position: absolute;
  margin-top: 12vw;
  margin-left: -2vw;
  height: 1.2vw;
  width: 8vw;
  border-radius: 2px;
}
.second {
  margin-top: 2.5vw;
}
