.section4 {
  display: flex;
  flex-direction: column;
  background-color: var(--orb-light-gray);
  position: relative;
}
.title-section4-container {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}
.yellow-line{
  position: absolute;
  top: 5.5vw;
  width: 90%;
  height: 1.2vw;
  transform: skew(-13deg);
  background-color: var(--orb-green);
  border-radius: 1.8px;
}
.title-session-4 {
  font-family: 'Panton';
  font-weight: 800;
  font-size: 180%;
  font-style: italic;
  color: var(--orb-dark-blue);
  width: 40%;
  text-align: center;
  z-index: 999;
  background-color: var(--orb-light-gray);
  padding-left: 5%;
  padding-right: 5%;
}
.blue-line-container {
  display: flex;
  justify-content: center;
}
.blue-line {
  width: 30%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--orb-dark-blue);
}
.buttons-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}
.button-buy-section4 {
  font-family: 'Panton';
  font-weight: 800;
  font-size: 110%;
  text-align: center;
  border-radius: 5px;
  width: 18vw;
  height: 3.6vw;
	transform: skew(-5deg);
  background-color: var(--orb-green);
  border-style: none;
  color: var(--orb-dark-blue);
}
.button-buy-section4:hover{
  background-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 1.8px;
  border-color: var(--orb-green);
  color: var(--orb-dark-blue);
}
.button-title {
  font-family: Panton;
  font-size: 70%;
  color: var(--orb-dark-blue);
  font-style: italic;
}
.buttons-inner-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2%
}