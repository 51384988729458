.mobile-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-around;
}
.mobile-header{
  display: flex;
  justify-content: center;
}
.button-buy-mobile {
  display: flex;
  height: 7vh;
  text-decoration: none;
	transform: skew(-9deg);
  background-color: rgba(0,0,0,0);
  margin-bottom: 25px;
  color: var(--orb-green);
  font-family: 'Panton';
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.5em;
  border-radius: 12px;
  border-color: var(--orb-green);
  border-width: 2.5px;
  border-style: solid;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.footer-mobile{
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-background{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: 'hidden';
  background-image: url('../../../images/BannerHomeOrb.png');
  margin-left: -940px;
  z-index: -999;
}